import { ref, shallowRef } from "#imports";
import {
  loadNavigationModule,
  loadPaginationModule,
  loadAutoplayModule,
  loadSwiper as loadSwiperCore,
  loadVirtualModule,
  loadGridModule,
} from "~/lib/swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/grid";

export default function useSwiper(options?: { virtual: boolean }) {
  const isSwiperLoaded = ref(false);
  const swiperComponent = {
    main: shallowRef(),
    slide: shallowRef(),
  };
  const swiperModule = {
    navigation: shallowRef(),
    pagination: shallowRef(),
    autoplay: shallowRef(),
    virtual: shallowRef(),
    grid: shallowRef(),
  };

  async function loadSwiper() {
    const swiper = await loadSwiperCore();
    const navigation = await loadNavigationModule();
    const pagination = await loadPaginationModule();
    const autoplay = await loadAutoplayModule();
    const grid = await loadGridModule();
    const virtual = options?.virtual ? await loadVirtualModule() : undefined;
    if (virtual) {
      swiperModule.virtual.value = virtual;
    }
    if (swiper && navigation && pagination && autoplay) {
      const { main, slide } = swiper;
      swiperComponent.main.value = main;
      swiperComponent.slide.value = slide;
      swiperModule.navigation.value = navigation;
      swiperModule.pagination.value = pagination;
      swiperModule.autoplay.value = autoplay;
      swiperModule.grid.value = grid;
      isSwiperLoaded.value = true;
    }
  }

  return {
    loadSwiper,
    isSwiperLoaded,
    swiperComponent,
    swiperModule,
  };
}
