async function loadSwiper() {
  try {
    const importSwiper = await import("swiper/vue");
    if (importSwiper) {
      const { Swiper, SwiperSlide } = importSwiper;
      return {
        main: Swiper,
        slide: SwiperSlide,
      };
    }
    return null;
  } catch (err) {
    return null;
  }
}

async function loadNavigationModule() {
  try {
    const { Navigation } = await import("swiper/modules");
    return Navigation;
  } catch (err) {
    return null;
  }
}

async function loadPaginationModule() {
  try {
    const { Pagination } = await import("swiper/modules");
    return Pagination;
  } catch (err) {
    console.log("bubu", err);
    return null;
  }
}

async function loadVirtualModule() {
  try {
    const { Virtual } = await import("swiper/modules");
    return Virtual;
  } catch (err) {
    return null;
  }
}

async function loadAutoplayModule() {
  try {
    const { Autoplay } = await import("swiper/modules");
    return Autoplay;
  } catch (err) {
    return null;
  }
}

async function loadGridModule() {
  try {
    const importSwiperModule = await import("swiper/modules");
    if (!importSwiperModule) {
      return null;
    }
    return importSwiperModule.Grid;
  } catch (err) {
    return null;
  }
}

export {
  loadSwiper,
  loadNavigationModule,
  loadPaginationModule,
  loadVirtualModule,
  loadAutoplayModule,
  loadGridModule,
};
